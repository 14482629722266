// REMOVEME: Remove this file after we go live with Neon content
import { useFeatureFlag } from './useFeatureFlag'
import { PRICE_CHANGE_ALERT } from '../constants'

/**
 * Returns a boolean value indicating whether neon price is changing
 *
 * @returns {Boolean} usePriceChangeAlertFlag
 */
const usePriceChangeAlertFlag = () => {
  const result = useFeatureFlag(PRICE_CHANGE_ALERT, false)
  return result
}

export { usePriceChangeAlertFlag }
