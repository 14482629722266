import React, { useEffect } from 'react'

import ProgressDots from '../components/progress-dots'
import CreateAccountForm from '../components/create-account-form'
import { signup } from '../../../lib/analytics/events'
import { useAuth } from '../../shared/hooks/use-auth'

import useStyles from './screen.styles'
import { signupSequence } from '../../shared/subscription-constants'

const CreateAccount = React.memo(() => {
  const styles = useStyles()
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (!isAuthenticated) {
      signup.start()
    }
  }, [])

  return (
    <>
      <ProgressDots steps={3} currentStep={signupSequence.createAccount} />

      <div className={styles.container}>
        <h1 className={styles.title}>Create your account</h1>
        <CreateAccountForm />
      </div>
    </>
  )
})

export default CreateAccount
