import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import Loader from '../components/loader'
import { signupImage } from '../../shared/subscription-constants'
import { STORAGE_KEY_VOUCHER_APPLICABLE_PLANS } from '../../../storage-keys'
import { firstPaymentLabel, firstPaymentDueLabel } from '../../change-subscription/constants'
import PlanDetails from '../components/plan-details'
import VoucherMessages from '../components/voucher-messages'
import { signup } from '../../../lib/analytics/events'
import { useAuth } from '../../shared/hooks/use-auth'
import { useSubscriptionConfirmation } from '../../shared/hooks/use-subscription-confirmation'
import { segmentTrackCompletePurchase } from '../../../segment/segment-track'
import { usePlanChangeFlag } from '../../../hooks/usePlanChange'

import useStyles from './screen.styles'
import { useCommonStyles } from '../lib/common.styles'

const Confirmation = () => {
  const styles = useStyles()
  const commonStyles = useCommonStyles()
  const { user } = useAuth()
  const getPlanDetails = usePlanChangeFlag()

  const {
    loading,
    details,
    orderId,
    voucher,
    hasSubscription,
    isTvodPlan,
    plan
  } = useSubscriptionConfirmation()

  useEffect(() => {
    if (hasSubscription) {
      signup.subscriptionConfirmationSeen(plan)
      signup.completeWithSubscription(user.id, orderId, true, plan)
    } else {
      signup.rentalOnlyConfirmationSeen()
      signup.completeWithoutSubscription(user.id)
    }

    // Clear the voucher info in session storage
    sessionStorage.removeItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS)
  }, [])

  useEffect(() => {
    // Add segment data analytics for completing purchase
    if (details.length > 0) {
      const selectedPlan = [getPlanDetails?.basic, getPlanDetails?.standard, getPlanDetails?.annual].find(item => item.title.toLowerCase() === plan.toLowerCase())
      const paymentIndex = details?.findIndex(e => e.name === firstPaymentLabel)
      const paymentDueIndex = details?.findIndex(e => e.name === firstPaymentDueLabel)
      if (paymentIndex >= 0 && paymentDueIndex >= 0) {
        segmentTrackCompletePurchase({
          planTitle: plan,
          planSku: selectedPlan?.sku,
          paymentDue: details[paymentDueIndex]?.value,
          payment: Number(details[paymentIndex]?.value?.replace('$', '')),
          orderId,
          voucher
        })
      }
    }
  }, [loading])

  const header = voucher
    ? 'Your voucher is good to go!'
    : "You're good to go!"

  const isConfirmationVisible = isTvodPlan ? true : hasSubscription

  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.title} data-e2e="signup-confirmation-text">{header}</h1>

        {!loading && isConfirmationVisible && (
          <div className={commonStyles.container}>

            {!isTvodPlan && <PlanDetails detail={details} />}

            {voucher && <VoucherMessages />}

            <img
              src={signupImage}
              alt="Plan confirmation"
              className={commonStyles.image}
            />

            <div className={commonStyles.buttons}>
              <NavLink to="/content-onboarding/profile-selection" className={commonStyles.primaryButton}>
                Set up your profile
              </NavLink>
            </div>
          </div>
        )}
      </div>

      <Loader visible={loading} />
    </>
  )
}

export default Confirmation
