import React, { useEffect, useState } from 'react'
import qs from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import TempPriceAlert from '../components/temp-price-alert/temp-price-alert'
import { LoadingComponent } from '../../../components/loading'
import ProgressDots from '../components/progress-dots'
import { signup } from '../../../lib/analytics/events'
import { useApiClient } from '../../shared/hooks/use-api-client'
import { useAuth } from '../../shared/hooks/use-auth'
import useStyles from './screen.styles'
import PlanSelectionStandardViewNew
  from '../components/plan-selection-standard-view/plan-selection-standard-view-new'
import PlanSelectionMediumView
  from '../components/plan-selection-medium-view/plan-selection-medium-view'
import { signupSequence } from '../../shared/subscription-constants'
import { useSelectPlan } from '../hooks/use-select-plan'

const PlanSelectionWithOffer = () => {
  const styles = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { voucher } = qs.parse(location.search)
  const [loading, setLoading] = useState(true)
  const { isAuthenticated, validateVoucherApplicable } = useAuth()
  const { doesUserHaveAnActiveSubscription } = useApiClient()
  const { selectToNext } = useSelectPlan()

  const checkIfUserHasActiveSubscription = async () => {
    const serverData = await doesUserHaveAnActiveSubscription()
    setLoading(false)

    if (serverData) {
      // when user is authenticated and has an active subscription, redirect back to home page
      history.push('/')
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      checkIfUserHasActiveSubscription()
    } else {
      // when user is not authenticated, redirect back to home page
      history.push('/')
    }

    signup.planSelectionSeen(signupSequence.planSelection)
  }, [])

  const nextStep = async (subscription) => {
    if (voucher && subscription) {
      const validateResult = await validateVoucherApplicable(voucher, subscription)
      if (validateResult.success) {
        selectToNext(subscription)
      }
    } else {
      selectToNext(subscription)
    }
  }

  if (loading) {
    return <LoadingComponent pastDelay />
  }

  return (
    <>
      <TempPriceAlert />
      <ProgressDots steps={3} currentStep={signupSequence.planSelection} />

      <div className={styles.container}>
        <h1 className={styles.title}>Welcome to Neon</h1>

        <PlanSelectionStandardViewNew selectToNext={nextStep} />
        <PlanSelectionMediumView selectToNext={nextStep} />
      </div>
    </>
  )
}

export default PlanSelectionWithOffer
