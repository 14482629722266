import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'query-string'

import { links, signupSequence, planType } from '../../shared/subscription-constants'
import { useAuth } from '../../shared/hooks/use-auth'
import { FORM_MESSAGES, MODALS, CHECK_GUEST_EMAIL_STORAGE_KEY } from '../../../constants'
import { signup } from '../../../lib/analytics/events'
import { useRedirectSignUp } from '../../shared/hooks/use-redirect-sign-up'
import { setSignupFormValue } from '../../../actions'
import { segmentTrackSignup } from '../../../segment/segment-track'

/**
 * Responsible for orchastrating the create account form. Provides
 * handlers for change, blur, etc for each field defined in the schema.
 *
 * @param {object} initialValues Initial values to populate the form
 * @param {object} schema Yup schema to define the shape and validation rules
 */
export const useCreateAccountForm = (initialValues, schema) => {
  const [stepSeen, setStepSeen] = useState(false)
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const mapState = useSelector(state => state)
  const { signupFormValue } = mapState
  const dispatch = useDispatch()

  const { createAccount, isAuthenticated } = useAuth()
  const history = useHistory()
  const location = useLocation()
  const { plan } = qs.parse(location.search)
  const { checkLocationToRedirect } = useRedirectSignUp(signupSequence.createAccount)

  if (isAuthenticated) {
    checkLocationToRedirect()
    history.push({
      ...location,
      pathname: links.planSelection
    })
  }

  if (!stepSeen && !isAuthenticated) {
    signup.createAccountSeen(signupSequence.createAccount, plan)

    setStepSeen(true)
  }

  useEffect(() => {
    // Init form values
    if (Object.keys(signupFormValue).length > 0) {
      const newValues = {
        ...initialValues,
        ...signupFormValue
      }

      // Add existing email
      const existingEmail = sessionStorage.getItem(CHECK_GUEST_EMAIL_STORAGE_KEY)
      if (existingEmail) {
        newValues.email = existingEmail
      }

      // Clear the voucher code for rental
      if (plan === planType.TVOD) {
        newValues.voucherCode = ''
      }

      setValues(newValues)
    }
  }, [])

  useEffect(() => {
    if (errors && errors.userExists) {
      history.replace({
        ...location,
        search: qs.stringify({
          ...qs.parse(location.search),
          modal: MODALS.qsParams.userExists,
          email: values.email
        })
      })
    }
  }, [errors])

  const validateForm = (valuesToValidate, touchedFields) => {
    try {
      schema.validateSync(valuesToValidate, { abortEarly: false })
      return {}
    } catch (err) {
      const validationErrors = {}

      err.inner.forEach(validationError => {
        const { path, message } = validationError

        if (!validationErrors[path] && touchedFields[path]) validationErrors[path] = message
      })

      return validationErrors
    }
  }

  const forceTouchAllFields = () => {
    const fieldNames = Object.keys(values)
    return fieldNames.reduce(
      (touchedFields, fieldName) => ({
        ...touchedFields,
        [fieldName]: true
      }),
      {}
    )
  }

  const updateState = (newValues, newTouched, newErrors) => {
    setValues(newValues)
    dispatch(setSignupFormValue(newValues))
    setTouched(newTouched)
    setErrors(newErrors)
  }

  const clearVoucherCode = () => {
    const newValues = { ...values }
    newValues.voucherCode = ''
    setValues(newValues)
  }

  const reportValidationErrors = errorsToReport => {
    const errorTypes = Object.keys(errorsToReport)
    const sequence = signupSequence.createAccount

    errorTypes.forEach(errorType => {
      signup.validationError(`${errorType}: ${errorsToReport[errorType]}`, sequence)
    })
  }

  const trackVoucherCode = () => {
    const { voucherCode } = values
    const parsedSearch = qs.parse(location.search)
    voucherCode && history.replace({
      ...location,
      search: qs.stringify({
        ...parsedSearch,
        voucher: voucherCode
      })
    })
  }

  const handleChange = e => {
    // Set values
    const { target } = e

    const newValue = oldValue => (target.type === 'checkbox' ? !oldValue : target.value)

    const newValues = {
      ...values,
      [target.name]: newValue(values[target.name])
    }

    const newTouched = {
      ...touched,
      [target.name]: true
    }

    const newErrors = validateForm(newValues, newTouched)
    updateState(newValues, newTouched, newErrors)

    if (target.type === 'checkbox') {
      signup.fieldComplete(target.name, target.checked, signupSequence.createAccount)
    }
  }

  const handleDropdownChange = (value, name) => {
    const newValues = {
      ...values,
      [name]: value.value
    }

    const newTouched = {
      ...touched,
      [name]: true
    }

    const newErrors = validateForm(newValues, newTouched)
    updateState(newValues, newTouched, newErrors)
  }

  /**
   * Binding event to push data to GA
   */
  const handleGATrack = event => {
    const { target } = event
    const sequence = signupSequence.createAccount

    const fieldName = target.name
    const error = errors[fieldName]
    const isTouched = touched[fieldName]

    // for text field
    if (!isTouched && target.type !== 'checkbox') return

    if (error) {
      signup.validationError(`${fieldName}: ${error}`, sequence)
      return
    }

    const fieldValue =
      target.type === 'checkbox' ? { value: target.checked ? 'yes' : 'no' } : undefined

    signup.fieldComplete(fieldName, fieldValue, sequence)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)

    const newTouched = forceTouchAllFields()
    const newErrors = validateForm(values, newTouched)

    if (Object.keys(newErrors).length > 0) {
      setSubmitting(false)

      reportValidationErrors(newErrors)
      updateState(values, newTouched, {
        ...newErrors,
        formError: FORM_MESSAGES.validationError
      })

      return
    }

    trackVoucherCode()

    const response = await createAccount(values)

    if (response.success) {
      // Add segment data analytics for sign up
      segmentTrackSignup({
        email: values.email,
        voucher: values.voucherCode,
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        birthYear: values.birthYear
      })
    }

    if (!response.success) {
      setSubmitting(false)

      if (response.errors?.voucherCode === 'VoucherApplicableError') {
        clearVoucherCode()
      } else {
        reportValidationErrors(response.errors)
        setErrors(response.errors)
      }
    }
  }

  return {
    values,
    errors,
    submitting,
    handleChange,
    handleDropdownChange,
    handleSubmit,
    handleGATrack,
    clearVoucherCode
  }
}
