import Yup from '../../../../lib/yup'
import { FORM_VALUES, FORM_MESSAGES } from '../../../../constants'

/**
 * Defines the schema and validation rules for the
 * create account form.
 */

export const schemaForAvod = Yup.object().shape({
  firstName: Yup.string().required(FORM_MESSAGES.required),
  lastName: Yup.string().required(FORM_MESSAGES.required),
  birthYear: Yup.number().nullable().required(FORM_MESSAGES.required),
  gender: Yup.string().required(FORM_MESSAGES.required),
  email: Yup.string()
    .email(FORM_MESSAGES.email.valid)
    .required(FORM_MESSAGES.required),
  confirmEmail: Yup.string()
    .email(FORM_MESSAGES.email.valid)
    .required(FORM_MESSAGES.required)
    .sameAs(Yup.ref('email'), FORM_MESSAGES.email.match),
  password: Yup.string()
    .required(FORM_MESSAGES.required)
    .min(FORM_VALUES.password.min, FORM_MESSAGES.password.min),
  acceptTerms: Yup.bool().oneOf(
    [true],
    'You must agree to the terms of use and privacy policy'
  )
})

export const initialValuesForAvod = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  password: '',
  birthYear: null,
  gender: '',
  acceptTerms: false,
  optInToNewsletter: true,
  voucherCode: ''
}
