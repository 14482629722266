import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useStyles } from './create-account-form.styles'

/**
 * Renders a password input field with the
 * supplied props.
 */
const PasswordInput = ({ label, error, ...inputProps }) => {
  const styles = useStyles()
  const [shouldShowPassword, setShouldShowPassword] = useState(false)

  const inputType = shouldShowPassword ? 'text' : 'password'
  const passwordButtonText = shouldShowPassword ? 'Hide' : 'Show'
  const togglePasswordVisibility = () => setShouldShowPassword(previousValue => !previousValue)

  return (
    <div className={styles.formGroup}>
      <label className={styles.label}>
        {label}

        <input
          {...inputProps}
          type={inputType}
          className={classNames(styles.input, {
            [styles.inputError]: Boolean(error)
          })}
        />

        <button
          type="button"
          className={styles.togglePasswordButton}
          onClick={togglePasswordVisibility}
        >
          {passwordButtonText}
        </button>
      </label>

      <p
        className={classNames(styles.fieldError, {
          [styles.visible]: Boolean(error)
        })}
      >
        {error}
      </p>
    </div>
  )
}

PasswordInput.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string
}

export default PasswordInput
